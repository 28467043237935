'use strict';

// Used to match keys in toggle-logic with feature flag checks in the code

module.exports = {
  addIndexToProductInfo: 'addIndexToProductInfo',
  displayWeightToggle: 'displayWeightToggle',
  displayHierarchyAndFilter: 'displayHierarchyAndFilter',
  displayCompetitorDownload: 'displayCompetitorDownload',
  displayCompetitorUpload: 'displayCompetitorUpload',
  displayCompetitorActionsMenu: 'displayCompetitorActionsMenu',
  displayAddCompetitorButton: 'displayAddCompetitorButton',
  displayLastUpdated: 'displayLastUpdated',
  workpackageExportFunction: 'workpackageExportFunction',
  includeNormWeight: 'includeNormWeight',
  displayRulesListView: 'displayRulesListView',
  competitorIndexWeightings: 'competitorIndexWeightings',
  useZones: 'useZones',
  competitorIndexWeightingTypes: {
    sales: 'sales',
    volume: 'volume',
    price: 'price',
  },
  numberWeeksHistoryLookForward: 'numberWeeksHistoryLookForward',
  displayStoreGroupPenalty: 'displayStoreGroupPenalty',
  displayStoreGroupCompetitors: 'displayStoreGroupCompetitors',
  beforePromotionDays: 'beforePromotionDays',
  displayExportToSAPButton: 'displayExportToSAPButton',
  displayExportPenaltyDataButton: 'displayExportPenaltyDataButton',
  displayExportToAPIButton: 'displayExportToAPIButton',
  showGoLiveDateDialog: 'showGoLiveDateDialog',
  displayHistoricalDropdown: 'displayHistoricalDropdown',
  unsetUMApprovalOnPriceChange: 'unsetUMApprovalOnPriceChange',
  exportWorkpackageOnUMApproval: 'exportWorkpackageOnUMApproval',
  extraDecimalPlacesForMarginDelta: 'extraDecimalPlacesForMarginDelta',
  getGroupedAggregations: 'getGroupedAggregations',
  getSingleAggregations: 'getSingleAggregations',
  enableGlobalRuleEdit: 'enableGlobalRuleEdit',
  allowTemplateIntentionCostChange: 'allowTemplateIntentionCostChange',
  displayWholesaleTab: 'displayWholesaleTab',
  displayV2Inputs: 'displayV2Inputs',
  displayV1Inputs: 'displayV1Inputs',
  displayArchitectureSubGroupSplittingAttributes: 'displayArchitectureSubGroupSplittingAttributes',
  exportPriceChanges: 'exportPriceChanges',
  allowWholesaleExport: 'allowWholesaleExport',
  useFirstFuturePrice: 'useFirstFuturePrice',
  enableExportWholesaleForceClose: 'enableExportWholesaleForceClose',
  displayWholesaleExportAll: 'displayWholesaleExportAll',
  displayWholesaleImportGrids: 'displayWholesaleImportGrids',
  displayGridsStoregroupsUpdate: 'displayGridsStoregroupsUpdate',
  useDefaultFilter: 'useDefaultFilter',
  defaultCategoryFilter: 'defaultCategoryFilter',
  useStickersPromotion: 'useStickersPromotion',
  useIndependentAttributeFilters: 'useIndependentAttributeFilters',
  recalculateAllOnWorkpackageCreation: 'recalculateAllOnWorkpackageCreation',
  allowedHierarchyLevelsForRecalculate: 'allowedHierarchyLevelsForRecalculate',
  runProductExistWithUnassignedPGAttribute: 'runProductExistWithUnassignedPGAttribute',
  addProductGoLiveDateAttribute: 'addProductGoLiveDateAttribute',
  // same as use_manual_product_go_live_date
  useManualProductGoLiveDate: 'useManualProductGoLiveDate',
  canAccessDevAdminTabs: 'canAccessDevAdminTabs',
  navbarColorHighlightAll: 'navbarColorHighlightAll',
  navbarColorHighlightOw: 'navbarColorHighlightOw',
  owUsersCanExport: 'owUsersCanExport',
  groupBySubGroupsAndArchitecture: 'groupBySubGroupsAndArchitecture',
  showCookieBanner: 'showCookieBanner',
};
