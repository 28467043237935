import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    [
      _c(
        VAppBar,
        {
          staticClass: "pricing-toolbar elevation-6",
          class: {
            warning: _vm.notUsingLocalDatabase,
            ..._vm.configurableNavbarColor,
          },
          attrs: { app: "" },
        },
        [
          _c(
            VToolbarItems,
            [
              _c(
                VBtn,
                {
                  staticClass: "subheading font-weight-bold home-link",
                  attrs: { exact: "", text: "", to: "/", depressed: "" },
                },
                [
                  _c(VImg, {
                    staticStyle: { visiblility: "invisible" },
                    attrs: {
                      id: "client-logo",
                      src: "/organisation-assets/logo.png",
                    },
                    on: { load: _vm.logoLoaded },
                  }),
                  _vm._v(" "),
                  _c(VImg, {
                    attrs: { src: _vm.pricingLogo, width: "12rem" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VSpacer),
          _vm._v(" "),
          _vm._l(_vm.routes, function (route, index) {
            return _c(
              VToolbarItems,
              { key: index },
              [
                route.meta && route.meta.featureFlag
                  ? [
                      _c(
                        "feature-toggle",
                        { attrs: { toggle: route.meta.featureFlag } },
                        [
                          _c("main-navbar-tab", {
                            attrs: {
                              route: route,
                              "is-work-package-selected":
                                _vm.isWorkPackageSelected,
                              "is-navbar-highlighted": _vm.isNavbarHighlighted,
                              index: index,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _c("main-navbar-tab", {
                      attrs: {
                        route: route,
                        "is-work-package-selected": _vm.isWorkPackageSelected,
                        "is-navbar-highlighted": _vm.isNavbarHighlighted,
                        index: index,
                      },
                    }),
              ],
              2
            )
          }),
          _vm._v(" "),
          _c(VSpacer),
          _vm._v(" "),
          _c(
            VToolbarItems,
            [
              _vm.isAdmin
                ? _c(
                    VBtn,
                    {
                      staticClass: "text-none subheading settings-link",
                      attrs: {
                        id: "navbar-settings",
                        text: "",
                        exact: "",
                        to: "/admin",
                        depressed: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "align-bottom" },
                        [_vm._m(0)],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "text-none subheading nav-link",
                  class: { "nav-link-dark": _vm.isNavbarHighlighted },
                  attrs: { id: "navbar-logout", text: "", depressed: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onLogout()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "align-bottom" }, [
                    _vm._v("Logout"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.includes(["home", "admin"], _vm.$route.name) &&
      _vm.isWorkPackageSelected
        ? _c(
            "div",
            { staticClass: "workpackage-selection" },
            [
              _c(
                VLayout,
                { attrs: { row: "" } },
                [
                  _c(VFlex, { attrs: { xs3: "", description: "" } }, [
                    _c(
                      "a",
                      { on: { click: _vm.goToWorkpackages } },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _vm.dateHasPassed(_vm.selectedWorkpackage.goLiveDate)
                          ? _c("span", [
                              _vm._v(
                                "\n            (" +
                                  _vm._s(_vm.$t("workPackages.archived")) +
                                  ")\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("truncate")(
                                _vm.selectedWorkpackage.description,
                                _vm.workpackageTruncationLength
                              )
                            ) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VFlex, { attrs: { xs2: "" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.selectedWorkpackage.storeFormat) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    VFlex,
                    {
                      staticClass: "text-xs-right",
                      attrs: { xs2: "", dates: "" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("workPackages.completionDate")) +
                          ":\n        "
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.completionDate) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(
                        "\n        / " +
                          _vm._s(_vm.$t("workPackages.goLiveDate")) +
                          ":\n        "
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatLocalisedDate(
                                _vm.selectedWorkpackage.goLiveDate
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(VFlex, { attrs: { xs2: "" } }, [
                    _c(
                      "div",
                      { staticClass: "actions" },
                      [_c("recalculate-all-button")],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    VFlex,
                    { attrs: { xs3: "" } },
                    [_vm.displayAlerts() ? _c("alerts") : _vm._e()],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, [_vm._v("mdi-settings")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      { staticClass: "font-weight-bold", attrs: { color: "#2f477c" } },
      [_vm._v(" chevron_left ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }