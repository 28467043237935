import axios from 'axios';
import {
  get,
  filter,
  groupBy,
  find,
  size,
  forEach,
  includes,
  isEmpty,
  join,
  omit,
  sortBy,
  map,
  some,
  set,
} from 'lodash';
import to from 'await-to-js';
import createMapping from '../utils/createMapping';
import hierarchyLevelNames from '@enums/hierarchy-upload';
import { unsetUMApprovalOnPriceChange, exportWorkpackageOnUMApproval } from '@enums/feature-flags';
import { unitLevel } from '@enums/hierarchy';
import exportType from '@enums/export-type';

const getInitialState = () => {
  return {
    hierarchy: {},
    hierarchyByStoreFormat: {},
    loading: false,
    loadingEmptyHierarchies: false,
    busyImportingGroup: false,
    productExistsWithIncompleteHierarchy: false,
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  getters: {
    // return all hierarchy documents for the currently selected workpackage
    getHierarchies(state, getters, rootState) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      return get(state.hierarchy, workpackageId);
    },

    // get the hierarchy for the current workpackage, optionally filtered
    getHierarchy: (state, getters, rootState) => hierarchyFilter => {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      return filter(get(state.hierarchy, workpackageId, []), hierarchyFilter);
    },

    getHierarchyForWorkpackage: state => (hierarchyFilter, workpackageId) => {
      return filter(get(state.hierarchy, workpackageId, []), hierarchyFilter);
    },

    hierarchyIdNameMap(state, getters, rootState) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      return createMapping(
        get(state.hierarchy, workpackageId, []),
        'levelEntryKey',
        'levelEntryDescription'
      );
    },
    // return all hierarchy documents for the currently selected workpackage, grouped by parentId
    getHierarchiesByParentId(state, rootState) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      return groupBy(get(state.hierarchy, workpackageId, []), 'parentId');
    },
    // retrieve the parent ID of a hierarchy entry based on a given levelEntryKey
    getHierarchyParentId: (_, getters) => levelEntryKey => {
      return get(find(getters.getHierarchies, { levelEntryKey }), 'parentId');
    },
    // retrieve the children of a hierarchy entry based on a given parentId
    getHierarchyChildren: (_, getters) => parentId => {
      return filter(getters.getHierarchies, { parentId });
    },

    hierarchyForCurrentArchitectureGroup: (state, getters, rootState) => {
      if (!rootState.filters.architectureGroup) return {};
      return getters.getHierarchy({ levelEntryKey: rootState.filters.architectureGroup })[0];
    },

    agChildrenForCurrentPricingGroup: (state, getters, rootState) => {
      if (!rootState.filters.pricingGroup) return {};
      return getters.getHierarchy({ parentId: rootState.filters.pricingGroup });
    },

    subGroupSplittingAttributeKeys: (state, getters, rootState) => {
      const scenario = find(rootState.scenarioMetadata.scenarioMetadata, {
        workpackageId: rootState.workpackages.selectedWorkpackage._id,
        scenarioKey: rootState.filters.scenario,
      });
      return get(
        scenario,
        ['subGroupSplittingAttributes', rootState.filters.architectureGroup],
        []
      ).map(({ attributeKey }) => attributeKey);
    },

    candidateScenariosWithSubGroupSplittingAttributes: (state, getters, rootState) => {
      return rootState.scenarioMetadata.scenarioMetadata.filter(scenarioMetadata => {
        if (scenarioMetadata.workpackageId !== rootState.workpackages.selectedWorkpackage._id)
          return false;
        if (scenarioMetadata.version !== 'candidate') return false;
        if (isEmpty(scenarioMetadata.subGroupSplittingAttributes)) return false;
        return true;
      });
    },

    getArchitectureSubGroupSplittingAttributes: (state, getters) => ({
      pricingGroupId,
      architectureGroupId,
    }) => {
      const matchingScenario = find(getters.candidateScenariosWithSubGroupSplittingAttributes, {
        hierarchyId: pricingGroupId,
      });
      const subGroupSplittingAttributes = get(
        matchingScenario,
        `subGroupSplittingAttributes.${architectureGroupId}`,
        []
      );
      return subGroupSplittingAttributes;
    },

    productKeySubGroupsMap: (state, getters, rootState) => {
      return get(rootState.architectureGroup, 'filteredAttributes', []).reduce(
        (keyMap, product) => {
          const productToolStoreGroupKey = `${product.productKey}::${product.toolStoreGroupKey}`;
          keyMap[productToolStoreGroupKey] = [];
          forEach(sortBy(product.attributes, 'attributeKey'), attribute => {
            if (
              includes(getters.subGroupSplittingAttributeKeys, attribute.attributeKey) &&
              attribute.attributeValue
            ) {
              keyMap[productToolStoreGroupKey].push(attribute.attributeValue);
            }
          });
          return keyMap;
        },
        {}
      );
    },

    // for architecture drivers page where architectureGroup must be selected
    getSubGroupsForProduct: (state, getters) => ({ productKey, toolStoreGroupKey }) => {
      return size(getters.productKeySubGroupsMap[`${productKey}::${toolStoreGroupKey}`])
        ? join(getters.productKeySubGroupsMap[`${productKey}::${toolStoreGroupKey}`], '-')
        : '-';
    },

    getApprovedUnitsForWorkpackage(state, getters, rootState) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const approvedUnits = map(
        filter(
          state.hierarchy[workpackageId],
          h => h.level === unitLevel && get(h, 'approvalStatus.unitManagerApproval.approved', false)
        ),
        h => h.levelEntryKey
      );
      return approvedUnits;
    },
  },

  mutations: {
    setHierarchy(state, { hierarchy, workpackageId }) {
      state.hierarchy = {
        ...state.hierarchy,
        [workpackageId]: hierarchy,
      };
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setBusyImportingGroup(state, importing) {
      state.busyImportingGroup = importing;
    },

    setloadingEmptyHierarchies(state, isLoading) {
      state.loadingEmptyHierarchies = isLoading;
    },

    setHierarchyByStoreFormat(state, { hierarchyByStoreFormat }) {
      state.hierarchyByStoreFormat = hierarchyByStoreFormat;
    },

    resetState(state) {
      Object.assign(state, getInitialState());
    },

    setProductExistsWithIncompleteHierarchy(state, productHasIncompleteHierarchy) {
      state.productExistsWithIncompleteHierarchy = productHasIncompleteHierarchy;
    },
  },

  actions: {
    async fetchHierarchy({ rootState, commit }, { params = {} } = {}) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      set(params, ['where', 'wholesale'], false);

      const [err, response] = await to(
        axios.get(`/api/hierarchy/workpackage/${workpackageId}`, { params })
      );

      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      const hierarchy = response.data;

      commit('setHierarchy', {
        hierarchy,
        workpackageId,
      });
      commit('setLoading', false);
      return hierarchy;
    },

    async postHierarchyUpload({ commit }) {
      commit('setBusyImportingGroup', false);
    },

    async fetchHierarchyForWorkpackage({ commit }, { params = {} } = {}) {
      commit('setLoading', true);
      const workpackageId = params.where.workpackageId;

      const [err, result] = await to(
        axios.get(`/api/hierarchy/workpackage/${workpackageId}`, { params })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      const hierarchy = result.data;
      commit('setHierarchy', {
        hierarchy,
        workpackageId,
      });
      commit('setLoading', false);
      return hierarchy;
    },

    async fetchHierarchiesForWorkpackages({ commit }, { params = {} } = {}) {
      commit('setLoading', true);
      // use post instead of get, as the workpackageId list in the params can be very long and hit GET request length restriction
      const [err, response] = await to(
        axios.post(`/api/hierarchy/hierarchiesForWorkpackages`, params)
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      forEach(response.data, hierarchyObject => {
        commit('setHierarchy', {
          hierarchy: hierarchyObject.hierarchy,
          workpackageId: hierarchyObject._id.workpackageId,
        });
      });
      commit('setLoading', false);
      return true;
    },

    async isEmptyHierarchy(state, { workpackageId, levelEntryKey, level } = {}) {
      const [err, result] = await to(
        axios.get(
          `/api/workpackage-product/workpackage/${workpackageId}/hierarchy/${levelEntryKey}/level/${level}/has-products`
        )
      );
      if (err) throw new Error(err.message);
      return !result.data.hasProducts;
    },

    // returns empty hierarchies
    async filterEmptyHierarchies({ commit, dispatch, getters }, hierarchyFilter) {
      commit('setloadingEmptyHierarchies', true);
      const hierarchies = getters.getHierarchy(hierarchyFilter);

      const isEmptyArr = await Promise.all(
        hierarchies.map(async child => dispatch('isEmptyHierarchy', child))
      ).catch(err => {
        commit('setloadingEmptyHierarchies', false);
        throw new Error(err.message);
      });

      commit('setloadingEmptyHierarchies', false);
      return hierarchies.filter((h, index) => isEmptyArr[index]);
    },

    async hierarchiesWithProducts({ rootState, getters, commit }, hierarchyFilter) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const { level } = hierarchyFilter;
      const params = {
        where: omit(hierarchyFilter, 'level'),
      };
      const [err, response] = await to(
        axios.get(
          `/api/workpackage-product/workpackage/${workpackageId}/level/${level}/has-products`,
          { params }
        )
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      const hierarchies = getters.getHierarchy(hierarchyFilter);
      const hierarchiesWithProducts = response.data;
      commit('setLoading', false);
      return hierarchies.filter(hierarchy =>
        hierarchiesWithProducts.includes(hierarchy.levelEntryKey)
      );
    },

    async createHierarchy({ rootState, dispatch }, hierarchy) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, result] = await to(
        axios.post(`/api/hierarchy/workpackage/${workpackageId}`, hierarchy)
      );
      if (err) throw new Error(err.message);
      dispatch('fetchHierarchy');
      return result.data;
    },

    async deleteHierarchy({ dispatch }, hierarchy) {
      const levelName = hierarchyLevelNames[hierarchy.levelDescription];
      const isEmptyHierarchy = await dispatch('isEmptyHierarchy', hierarchy);
      const { levelEntryKey, workpackageId, _id } = hierarchy;

      if (!isEmptyHierarchy) {
        throw new Error(
          `Hierarchy ${hierarchy.levelEntryDescription} is not empty, so it can't be deleted.`
        );
      }

      const [err, result] = await to(
        axios.delete(
          `/api/hierarchy/workpackage/${workpackageId}/${levelName}/${_id}/levelEntryKey/${levelEntryKey}`
        )
      );

      if (err) throw new Error(err.message);

      dispatch('fetchHierarchy');
      return result.data;
    },

    async updateHierarchy({ rootState, commit, dispatch }, { updates = {}, levelEntryKey } = {}) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.patch(`/api/hierarchy/workpackage/${workpackageId}/${levelEntryKey}`, updates)
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      dispatch('fetchHierarchy');
    },

    async markUnitApprove(
      { rootState, dispatch },
      { updates = {}, levelEntryKey, sapExportFields } = {}
    ) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.patch(
          `/api/approve/unit/workpackage/${workpackageId}/hierarchy/${levelEntryKey}`,
          updates
        )
      );
      if (err) {
        throw new Error(err.message);
      }
      dispatch('fetchHierarchy');
      if (rootState.clientConfig.toggleLogic[exportWorkpackageOnUMApproval]) {
        // PRICE-787, trigger export SAP for a single unit
        const exportToSAPConfig = rootState.clientConfig.exportConfigs.exportToSAP;
        const params = {
          selectedWorkpackage: rootState.workpackages.selectedWorkpackage,
          fieldConfigs: sapExportFields,
          exportConfig: exportToSAPConfig,
          exportType: exportType.exportToSAP,
          toFTP: true,
        };
        dispatch('workpackages/downloadExportToSAP', params, { root: true });
      }
    },

    async unsetUMApproval({ rootState, dispatch }, { levelEntryKey } = {}) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const updates = { 'approvalStatus.unitManagerApproval': null };
      const [err] = await to(
        axios.patch(
          `/api/approve/unit/workpackage/${workpackageId}/hierarchy/${levelEntryKey}`,
          updates
        )
      );
      if (err) {
        throw new Error(err.message);
      }
      dispatch('fetchHierarchy');
    },

    async unsetUMApprovalIfPriceChanged({ rootState, dispatch, getters }, { levelEntryKey }) {
      // check feature flag
      if (!rootState.clientConfig.toggleLogic[unsetUMApprovalOnPriceChange]) return;
      // check if unit has UM approval
      const approvedUnits = getters.getApprovedUnitsForWorkpackage;
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      if (!includes(approvedUnits, levelEntryKey)) return;
      let newPrices;
      let existingPrices;
      // get old export result
      const [fetchErr, exportedResult] = await to(
        axios.get(`/api/product-export-flags/workpackage/${workpackageId}`)
      );
      if (fetchErr) {
        throw new Error(fetchErr.message);
      }
      const prevResults = exportedResult.data;
      if (prevResults) {
        existingPrices = prevResults.reduce((mapObj, result) => {
          return {
            ...mapObj,
            [`${result.productKey}::${result.pricingStoreGroupKey}`]: result.price,
          };
        }, {});
      }
      // get new result and don't update ProductExportFlags when fetching the results
      const params = { selectedUnitId: levelEntryKey, updateProductExportFlags: false };
      const [err, res] = await to(
        axios.get(`/api/work-packages/workpackage/${workpackageId}/rawResults`, { params })
      );
      if (err) {
        throw new Error(err.message);
      }
      const rawResults = res.data;
      if (rawResults) {
        newPrices = rawResults.reduce((mapObj, result) => {
          return { ...mapObj, [`${result.productKey}::${result.storeFormat}`]: result.price };
        }, {});
      }
      // compare results, set UM approval to null if there's a price change
      if (some(newPrices, (price, key) => price !== get(existingPrices, key))) {
        dispatch('unsetUMApproval', { levelEntryKey });
      }
    },

    async updatePgApproval({ rootState, dispatch }, { updates = {}, levelEntryKey } = {}) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.patch(
          `/api/approve/pg/workpackage/${workpackageId}/hierarchy/${levelEntryKey}`,
          updates
        )
      );
      if (err) {
        throw new Error(err.message);
      }
      dispatch('fetchHierarchy');
    },

    async updateHierarchies({ rootState, commit, dispatch }, { updates }) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err] = await to(axios.patch(`/api/hierarchy/workpackage/${workpackageId}`, updates));
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      commit('setLoading', false);
      dispatch('fetchHierarchy');
    },

    async updateTargetDistances({ rootState, dispatch }, { updates = {}, levelEntryKey } = {}) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      // both PM and PS should be able to update target distance, but PM don't have hierarchyWritePermission.
      const [err] = await to(
        axios.patch(
          `/api/hierarchy/target-distance/workpackage/${workpackageId}/${levelEntryKey}`,
          updates
        )
      );
      if (err) {
        throw new Error(err.message);
      }
      dispatch('fetchHierarchy');
    },

    async updateCategoryApproval({ rootState, commit, dispatch }, { updates }) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err] = await to(
        axios.patch(`/api/approve/category/workpackage/${workpackageId}`, updates)
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      commit('setLoading', false);
      dispatch('fetchHierarchy');
    },

    async uploadDataFile({ rootState }, dataFile) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, res] = await to(
        axios.post(`/api/hierarchy/upload/workpackage/${workpackageId}/validate`, dataFile, {
          headers: {
            // Now axios 1.x set the Content-Type to 'application/json' automatically so in order to have the value as 'FormData/HTMLForm' we need to set as undefined
            // You can read more about in the axios migration guide to v1.x on https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
            'Content-Type': undefined,
          },
        })
      );
      if (err) throw err.response.data;
      return res.data;
    },

    async confirmUpload({ dispatch, rootState, commit }, { params, primaryColumn }) {
      commit('setBusyImportingGroup', true);
      // store params for re-fetching after upload is complete
      dispatch('filters/setInputScreensFetchParams', params, { root: true });
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, res] = await to(
        axios.post(`/api/hierarchy/upload/workpackage/${workpackageId}`, {
          params: { primaryColumn },
        })
      );
      if (err) throw new Error(err.message);

      // Once you've uploaded assignments, ensure the engine has been triggered for new PGs
      // Use timeout as otherwise the toasts can come too quickly to be readable by the user.
      setTimeout(() => {
        dispatch('initialiseNewPricingGroups');
      }, 500);

      if (res) return res;
    },

    async fetchHierarchyByStoreFormat({ commit }, { params = {} } = {}) {
      commit('setLoading', true);

      const [err, response] = await to(axios.get('/api/hierarchy/by-store-format', { params }));

      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      const { hierarchyByStoreFormat } = response.data;

      commit('setHierarchyByStoreFormat', {
        hierarchyByStoreFormat,
      });
      commit('setLoading', false);
      return hierarchyByStoreFormat;
    },

    resetState({ commit }) {
      commit('resetState');
    },

    async doesProductExistWithIncompleteHierarchy({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const params = { workpackageId };
      const [err, response] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/unassignedHierarchies`, {
          params,
        })
      );
      if (err) {
        throw new Error(err.message);
      }
      commit('setProductExistsWithIncompleteHierarchy', response.data);
    },

    async initialiseNewPricingGroups({ rootState, dispatch }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [errPgs, uninitialisedPgs] = await to(
        axios.get(`/api/hierarchy/workpackage/${workpackageId}/uninitialised-pricing-groups`)
      );

      const { data: pgKeys } = uninitialisedPgs;

      if (errPgs) console.error(errPgs);

      if (pgKeys.length) {
        console.log(`TRIGGERING ENGINE FOR ${pgKeys}`);
        dispatch('gridView/runEngineForSpecificPricingGroups', { pgKeys }, { root: true });
      }
    },
  },
};

export default store;
