<template>
  <tbody>
    <tr>
      <td v-if="isUnitLevel" colspan="35"><br /><br /></td>
    </tr>
    <tr class="hierarchy__row hierarchy-level-item__main" :class="[hierarchyLevelClass]">
      <td
        class="hierarchy-level-item__heading hierarchy-level-item__group-end border-right"
        :class="{ disabled: rowDisabled }"
      >
        <div class="d-flex justify-space-between">
          <div
            class="clickable my-auto"
            :style="[{ fontSize: headingSizeInRem + 'rem' }]"
            @click="toggleExpansion"
          >
            <i
              v-if="isPricingGroupLevel || isCategoryLevel"
              :class="[expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right']"
            />
            {{ name }}
          </div>
          <div class="d-flex align-center justify-end ">
            <v-progress-circular
              v-if="recalculatingSpecificPgs.length"
              :custom-title-msg="
                $t('gridView.tooltips.specificPgsRecalculating', {
                  pgNames: recalculatingSpecificPgs.map(pg => pg.levelEntryDescription).join(', '),
                })
              "
              :size="20"
              :width="2"
              color="primary"
              indeterminate
              >i</v-progress-circular
            >
            <div class="actions">
              <v-btn
                v-if="isUnitLevel && canApproveUnit && !unitApproved"
                class="mr-2 hierarchy-level-item__approve-btn approval-workflow"
                :class="{ 'disabled-button': isSelectedWorkpackageMaster }"
                color="success"
                small
                depressed
                :disabled="unitApproved || isSelectedWorkpackageMaster"
                @click="approveUnit()"
              >
                {{ $t('actions.approve') }}
              </v-btn>
              <span v-if="unitApproved && isUnitLevel" class=" mr-2 approval-text success--text">
                <strong>{{ $t('actions.approved') }}: {{ approvalDate | delimiter }}</strong>
              </span>
              <template v-if="isRootHierarchyLevel">
                <recalculate-all-button />
              </template>
              <template v-else>
                <v-btn
                  v-if="showRecalculateButton"
                  color="primary"
                  x-small
                  depressed
                  :loading="isLoading"
                  :disabled="isRecalculateDisabled"
                  class="mr-2 btn-refresh white--text hierarchy-level-item__recalculate-btn"
                  @click="triggerRecalculate"
                >
                  {{ isRootHierarchyLevel ? $t('actions.recalculateAll') : '' }}
                  <v-icon v-once size="2rem">mdi-refresh</v-icon>
                </v-btn>
              </template>

              <div
                v-if="isPricingGroupLevel"
                :custom-title-msg="$t('gridView.tooltips.pricingSpecialistRelease')"
                custom-title-position="top-right"
              >
                <v-checkbox
                  class="ma-0 checkbox"
                  :input-value="selectedOrReleased"
                  :readonly="
                    pgReleased ||
                      !pricingSpecialistAndManagerPricingGroupLevel ||
                      isSelectedWorkpackageMaster
                  "
                  dense
                  color="success"
                  :disabled="
                    pgReleased ||
                      !pricingSpecialistAndManagerPricingGroupLevel ||
                      isSelectedWorkpackageMaster
                  "
                  @change="togglePricingGroup()"
                />
              </div>

              <hierarchy-level-item-approvals v-if="isPricingGroupLevel" :approvals="approvals" />

              <v-dialog v-model="recalculateConfirmationDialog" width="40rem">
                <v-card>
                  <v-card-title class="headline lighten-2" primary-title>
                    {{ $t('gridView.recalculate.confirmationDialog.title', { hierarchy: name }) }}
                  </v-card-title>
                  <v-card-text>
                    <div class="recalculate-dialog pt-5 pb-7">
                      {{
                        $t('gridView.recalculate.confirmationDialog.description', {
                          hierarchy: get(
                            recalculatingUpperHierarchy,
                            '[0].levelEntryDescription',
                            ''
                          ),
                        })
                      }}
                    </div>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        outlined
                        class="cancel-btn"
                        small
                        text
                        depressed
                        @click="recalculateConfirmationDialog = false"
                      >
                        {{ $t('actions.cancel') }}
                      </v-btn>
                      <v-btn class="primary" small depressed @click="recalculate">
                        {{ $t('actions.submit') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </td>
      <aggregations-row
        :level="level"
        :sales-m-a-t="salesMAT"
        :promo-share="promoShare"
        :sales-impact-m-a-t="salesImpactMAT"
        :regular-sales-impact="regularSalesImpact"
        :total-impact-in-year="totalImpactInYear"
        :regular-impact-in-year="regularImpactInYear"
        :total-cost-impact="totalCostImpact"
        :cost-impact-in-year="costImpactInYear"
        :regular-cost-impact-in-year="regularCostImpactInYear"
        :regular-cost-impact="regularCostImpact"
        :margin-proposed="marginProposed"
        :margin-delta="marginDelta"
        :economic-tension="economicTension"
        :competitor-tension="competitorTension"
        :architecture-tension="architectureTension"
        :store-group-tension="storeGroupTension"
        :competitor1-live-distance="competitor1LiveDistance"
        :competitor1-proposed-distance="competitor1ProposedDistance"
        :competitor1-delta-vs-prev="competitor1DeltaVsPrev"
        :competitor2-live-distance="competitor2LiveDistance"
        :competitor2-proposed-distance="competitor2ProposedDistance"
        :competitor2-delta-vs-prev="competitor2DeltaVsPrev"
        :competitor1-live-distance-price="competitor1LiveDistancePrice"
        :competitor1-proposed-distance-price="competitor1ProposedDistancePrice"
        :competitor1-delta-vs-prev-price="competitor1DeltaVsPrevPrice"
        :competitor2-live-distance-price="competitor2LiveDistancePrice"
        :competitor2-proposed-distance-price="competitor2ProposedDistancePrice"
        :competitor2-delta-vs-prev-price="competitor2DeltaVsPrevPrice"
        :competitor1-live-distance-volume="competitor1LiveDistanceVolume"
        :competitor1-proposed-distance-volume="competitor1ProposedDistanceVolume"
        :competitor1-delta-vs-prev-volume="competitor1DeltaVsPrevVolume"
        :competitor2-live-distance-volume="competitor2LiveDistanceVolume"
        :competitor2-proposed-distance-volume="competitor2ProposedDistanceVolume"
        :competitor2-delta-vs-prev-volume="competitor2DeltaVsPrevVolume"
        :num-price-changes="numPriceChanges"
        :competitor-target-distances="competitorTargetDistances"
        @updateCompetitorTargetDistances="updateCompetitorTargetDistances"
      />
    </tr>
    <feature-toggle :toggle="displayStoreGroupPenalty">
      <store-group-aggregations :key="_id" :parent-id="_id" :level="level" />
    </feature-toggle>
    <tr v-if="showCheckboxRow" class="hierarchy__row checkbox-row">
      <hierarchy-level-item-expand-children
        v-if="showExpandChildrenActionRow"
        :expanded="expanded"
        :level="level"
        :level-entry-key="levelEntryKey"
        :name="name"
        :parent-id="parentId"
        :workpackage-id="workpackageId"
        @categoryReleased="toggleCategory()"
        @pricingSpecialistCategoryApproval="pricingSpecialistCategoryApproval"
        @categoryManagerCategoryApproval="categoryManagerCategoryApproval"
        @toggle="toggleExpansion"
      />
      <td v-else colspan="15" />
      <td
        colspan="2"
        :class="{
          'border-bottom': areStoreGroupAggregationsVisible && showExpandChildrenActionRow,
        }"
      >
        <feature-toggle :toggle="displayStoreGroupPenalty">
          <div
            v-if="areStoreGroupAggregationsVisible"
            class="clickable my-auto"
            @click="toggleDisplayStoreGroupAggregations"
          >
            {{ storeGroupExpanded ? $t('actions.close') : $t('actions.details') }}
            <i :class="[storeGroupExpanded ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down']" />
          </div>
        </feature-toggle>
      </td>
      <td class="alert-cell" />
    </tr>
  </tbody>
</template>

<script>
import { get, includes, isEmpty } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import { competitorIndexWeightingTypes, displayStoreGroupPenalty } from '@enums/feature-flags';
import numberFormats from '@enums/number-formats';

export default {
  filters: {
    delimiter(value) {
      return value.replace(/\//g, '-');
    },
  },
  props: {
    isUnitManagerView: Boolean,
    expandedHierarchyLevelItems: Array,
    // eslint-disable-next-line vue/prop-name-casing
    _id: String,
    parentId: String,
    name: String,
    level: {
      type: Number,
      default: () => 0,
    },
    levelEntryKey: String,
    workpackageId: String,
    salesMAT: Number,
    promoShare: Number,
    salesImpactMAT: Number,
    regularSalesImpact: Number,
    totalImpactInYear: Number,
    regularImpactInYear: Number,
    totalCostImpact: Number,
    costImpactInYear: Number,
    regularCostImpactInYear: Number,
    regularCostImpact: Number,
    marginProposed: Number,
    marginDelta: Number,
    economicTension: Number,
    competitorTension: Number,
    architectureTension: Number,
    storeGroupTension: Number,

    competitor1LiveDistance: Number,
    competitor1ProposedDistance: Number,
    competitor1DeltaVsPrev: Number,
    competitor2LiveDistance: Number,
    competitor2ProposedDistance: Number,
    competitor2DeltaVsPrev: Number,

    competitor1LiveDistancePrice: Number,
    competitor1ProposedDistancePrice: Number,
    competitor1DeltaVsPrevPrice: Number,
    competitor2LiveDistancePrice: Number,
    competitor2ProposedDistancePrice: Number,
    competitor2DeltaVsPrevPrice: Number,

    competitor1LiveDistanceVolume: Number,
    competitor1ProposedDistanceVolume: Number,
    competitor1DeltaVsPrevVolume: Number,
    competitor2LiveDistanceVolume: Number,
    competitor2ProposedDistanceVolume: Number,
    competitor2DeltaVsPrevVolume: Number,

    numPriceChanges: Number,
    released: {
      type: Object,
      default: () => {},
    },
    approvalStatus: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      approvalOptions: [true, false],
      expanded: false,
      competitorTargetDistances: [],
      numberFormats,
      displayStoreGroupPenalty,
      recalculateConfirmationDialog: false,
      get,
    };
  },

  computed: {
    ...mapState('gridView', [
      'selectedWeighting',
      'pricingGroupsToRelease',
      'calculatingScenarioResults',
      'calculatingPgResults',
      'calculatingSpecificPgResults',
      'recalculatingAll',
      'selectedCompetitors',
      'selectedStoreGroup',
      'activeTableFilters',
    ]),
    ...mapGetters('gridView', ['expandedStoreGroupAggregation']),
    ...mapGetters('context', [
      'isUnitManager',
      'isPricingManager',
      'isPricingSpecialist',
      'isCategoryManager',
      'isWholesaleManager',
      'isWholesaleAnalyst',
      'isAdminLoggedIn',
    ]),
    ...mapGetters('workpackages', ['isSelectedWorkpackageMaster']),
    ...mapState('hierarchy', ['hierarchy']),
    ...mapGetters('hierarchy', ['getHierarchy', 'getHierarchyChildren']),
    ...mapState('clientConfig', {
      userRoles: 'userRoles',
      hierarchyConfig: 'hierarchy',
      toggleLogic: 'toggleLogic',
    }),

    storeGroupExpanded() {
      return !!this.expandedStoreGroupAggregation({
        parentId: this._id,
        level: this.level,
        scenarioKey: null,
      });
    },

    weightingFunctionMap() {
      return {
        [competitorIndexWeightingTypes.sales]: this.sales,
        [competitorIndexWeightingTypes.volume]: this.volume,
        [competitorIndexWeightingTypes.price]: this.price,
      };
    },

    selectedWeightValues() {
      return this.weightingFunctionMap[this.selectedWeighting];
    },

    sales() {
      return {
        competitor1LiveDistance: this.competitor1LiveDistance,
        competitor1ProposedDistance: this.competitor1ProposedDistance,
        competitor1DeltaVsPrev: this.competitor1DeltaVsPrev,
        competitor2LiveDistance: this.competitor2LiveDistance,
        competitor2ProposedDistance: this.competitor2ProposedDistance,
        competitor2DeltaVsPrev: this.competitor2DeltaVsPrev,
      };
    },

    price() {
      return {
        competitor1LiveDistance: this.competitor1LiveDistancePrice,
        competitor1ProposedDistance: this.competitor1ProposedDistancePrice,
        competitor1DeltaVsPrev: this.competitor1DeltaVsPrevPrice,
        competitor2LiveDistance: this.competitor2LiveDistancePrice,
        competitor2ProposedDistance: this.competitor2ProposedDistancePrice,
        competitor2DeltaVsPrev: this.competitor2DeltaVsPrevPrice,
      };
    },

    volume() {
      return {
        competitor1LiveDistance: this.competitor1LiveDistanceVolume,
        competitor1ProposedDistance: this.competitor1ProposedDistanceVolume,
        competitor1DeltaVsPrev: this.competitor1DeltaVsPrevVolume,
        competitor2LiveDistance: this.competitor2LiveDistanceVolume,
        competitor2ProposedDistance: this.competitor2ProposedDistanceVolume,
        competitor2DeltaVsPrev: this.competitor2DeltaVsPrevVolume,
      };
    },

    hierarchyLevelClass() {
      return `hierarchy-level-item--level-${this.level}`;
    },

    headingSizeInRem() {
      // TODO: extract out to config or create css classes for these
      const headingSizes = [1.8, 1.8, 1.6, 1.4];
      return headingSizes[this.level];
    },

    showExpandChildrenActionRow() {
      return (
        !this.isUnitManagerView &&
        this.level > this.hierarchyConfig.rootHierarchyLevel &&
        this.level < this.hierarchyConfig.pricingGroupLevel
      );
    },

    isCategoryLevel() {
      return this.level === this.hierarchyConfig.categoryLevel;
    },

    isPricingGroupLevel() {
      return this.level === this.hierarchyConfig.pricingGroupLevel;
    },

    rowDisabled() {
      if (this.level === this.hierarchyConfig.pricingGroupLevel) {
        return (
          get(this.released, 'isReleased', false) === false &&
          !(
            this.isPricingSpecialist ||
            this.isPricingManager ||
            this.isWholesaleManager ||
            this.isWholesaleAnalyst
          )
        );
      }
      return false;
    },

    selectedOrReleased() {
      return (
        this.pricingGroupsToRelease.some(pg => pg.levelEntryKey === this.levelEntryKey) ||
        this.pgReleased
      );
    },

    psApproved() {
      const pricingGroup = this.getHierarchy(pg => pg.levelEntryKey === this.levelEntryKey)[0];
      return get(pricingGroup, 'approvalStatus.pricingSpecialistApproval.approved', null); // can't default to true or false here as there could be a situation where no choice has been made
    },

    cmApproved() {
      const pricingGroup = this.getHierarchy(pg => pg.levelEntryKey === this.levelEntryKey)[0];
      return get(pricingGroup, 'approvalStatus.categoryManagerApproval.approved', null); // can't default to true or false here as there could be a situation where no choice has been made
    },

    pgReleased() {
      const pricingGroup = this.getHierarchy(pg => pg.levelEntryKey === this.levelEntryKey)[0];
      return get(pricingGroup, 'released.isReleased', null) === true;
    },

    pricingSpecialistAndManagerPricingGroupLevel() {
      return this.isPricingGroupLevel && (this.isPricingSpecialist || this.isPricingManager);
    },

    categoryManagerPricingGroupLevel() {
      return this.isPricingGroupLevel && this.isCategoryManager;
    },

    pgNotReleased() {
      const pricingGroup = this.getHierarchy(pg => pg.levelEntryKey === this.levelEntryKey)[0];
      return get(pricingGroup, 'released.isReleased', false) === false;
    },

    pgNotCmApproved() {
      const pricingGroup = this.getHierarchy(pg => pg.levelEntryKey === this.levelEntryKey)[0];
      return get(pricingGroup, 'approvalStatus.categoryManagerApproval.approved', false) === false;
    },

    isUnitLevel() {
      return this.level === this.hierarchyConfig.unitLevel;
    },

    unitApproved() {
      const unit = this.getHierarchy(u => u.levelEntryKey === this.levelEntryKey)[0];
      return get(unit, 'approvalStatus.unitManagerApproval.approved', false);
    },

    canApproveUnit() {
      return this.isUnitManager || this.isPricingManager;
    },

    canWriteTargetDistance() {
      return this.isPricingManager || this.isPricingSpecialist;
    },

    approvalDate() {
      const unit = this.getHierarchy(u => u.levelEntryKey === this.levelEntryKey)[0];
      return this.formatLocalisedDate(
        get(unit, 'approvalStatus.unitManagerApproval.approvalDate', null)
      );
    },

    approvals() {
      return [
        {
          id: this.userRoles.categoryManager,
          tooltipMessage: this.$t('gridView.tooltips.categoryManagerApproval'),
          value: this.cmApproved,
          disabled: this.pgNotReleased || !this.categoryManagerPricingGroupLevel,
          change: this.categoryManagerPricingGroupApproval,
        },
        {
          id: this.userRoles.pricingSpecialist,
          tooltipMessage: this.$t('gridView.tooltips.pricingSpecialistApproval'),
          value: this.psApproved,
          disabled: !this.pricingSpecialistAndManagerPricingGroupLevel,
          change: this.pricingSpecialistPricingGroupApproval,
        },
      ];
    },

    areStoreGroupAggregationsVisible() {
      return this.level < this.hierarchyConfig.pricingGroupLevel;
    },

    showCheckboxRow() {
      return (
        this.isRootHierarchyLevel ||
        (this.showExpandChildrenActionRow &&
          (!this.isCategoryLevel || (this.isCategoryLevel && this.expanded))) ||
        (this.level === this.hierarchyConfig.unitLevel && this.isUnitManagerView)
      );
    },

    isPermissionDenied() {
      return (this.isWholesaleManager || this.isWholesaleAnalyst) && !this.isAdminLoggedIn;
    },

    isRootHierarchyLevel() {
      return this.level === this.hierarchyConfig.rootHierarchyLevel;
    },

    isLoading() {
      return this.isRootHierarchyLevel ? this.recalculatingAll : this.isRecalculatingPgs;
    },

    isRecalculatingPgs() {
      return get(this.calculatingPgResults, `${this.workpackageId}?${this.levelEntryKey}`, false);
    },

    // List of upper-level hierarchies being recalculated
    recalculatingUpperHierarchy() {
      return [...this.recalculatingRootHierarchy, ...this.recalculatingParentHierarchy];
    },

    recalculatingRootHierarchy() {
      return this.recalculatingAll
        ? this.getHierarchy(h => h.level === this.hierarchyConfig.rootHierarchyLevel)
        : [];
    },

    recalculatingParentHierarchy() {
      return get(this.calculatingPgResults, `${this.workpackageId}?${this.parentId}`, false)
        ? this.getHierarchy(h => h.levelEntryKey === this.parentId)
        : [];
    },

    isRecalculateDisabled() {
      return this.isPermissionDenied || this.isRecalculatingPgs;
    },

    showRecalculateButton() {
      return this.toggleLogic.allowedHierarchyLevelsForRecalculate.includes(this.level);
    },
    // List of child hierarchies being recalculated
    recalculatingSpecificPgs() {
      if (!this.isCategoryLevel) {
        return [];
      }
      const res = this.getHierarchyChildren(this.levelEntryKey).filter(ch =>
        get(this.calculatingSpecificPgResults, `${this.workpackageId}?${ch.levelEntryKey}`, false)
      );
      return res;
    },
  },

  created() {
    this.getCompetitorTargetDistance();
    if (includes(this.expandedHierarchyLevelItems, this._id)) {
      this.expanded = true;
    }
    // Check if the engine is running for the category/unit
    if (this.showRecalculateButton && !this.isRootHierarchyLevel) {
      this.checkRecalculatingPgs({
        workpackageId: this.workpackageId,
        levelEntryKey: this.levelEntryKey,
      });
    }
  },

  events: {
    onHierarchyRefreshed() {
      this.getCompetitorTargetDistance();
    },

    onToggleExpansion({ parentId, level }) {
      // collapse if some other item is being expanded
      if (parentId !== this._id && level <= this.level) {
        this.expanded = false;
      }
    },
  },

  methods: {
    ...mapActions('gridView', [
      'updatePricingGroupsToRelease',
      'setCategoryToRelease',
      'runEngineForPricingGroups',
      'removeActiveTableFilter',
      'toggleStoreGroupAggregations',
      'checkRecalculatingPgs',
    ]),
    ...mapActions('hierarchy', ['updateTargetDistances']),

    toggleExpansion() {
      if (this.rowDisabled) return;
      this.expanded = !this.expanded;

      // always emit toggle-expansion to make sure pricing group expansion change is also synced.
      this.globalEmit('toggle-expansion', {
        parentId: this._id,
        level: this.level,
        beingExpanded: this.expanded,
      });

      // clicking on a pricing group row or clicking on unit row in unitMangerView
      // will expand the product view
      if (
        this.level === this.hierarchyConfig.pricingGroupLevel ||
        (this.level === this.hierarchyConfig.unitLevel && this.isUnitManagerView)
      ) {
        this.removeActiveTableFilter({ filter: this.activeTableFilters, skipFetch: true });
        this.globalEmit('expand-product-view', { levelEntryKey: this._id });
      }
    },

    toggleCategory() {
      this.setCategoryToRelease({
        category: { levelEntryKey: this.levelEntryKey, name: this.name, parentId: this.parentId },
      });
      this.$emit('releaseCategory', {
        levelEntryKey: this.levelEntryKey,
        name: this.name,
        parentId: this.parentId,
      });
    },

    togglePricingGroup() {
      this.updatePricingGroupsToRelease({
        pg: { levelEntryKey: this.levelEntryKey, name: this.name, parentId: this.parentId },
      });
      this.$emit('releasePricingGroup');
    },

    async toggleDisplayStoreGroupAggregations() {
      await this.toggleStoreGroupAggregations({ parentId: this._id, level: this.level });
    },

    pricingSpecialistPricingGroupApproval({ approved }) {
      this.$emit('pricingSpecialistPricingGroupApproval', {
        levelEntryKey: this.levelEntryKey,
        approved,
      });
    },

    pricingSpecialistCategoryApproval({ approved }) {
      this.$emit('pricingSpecialistCategoryApproval', {
        levelEntryKey: this.levelEntryKey,
        approved,
      });
    },

    categoryManagerPricingGroupApproval({ approved }) {
      this.$emit('categoryManagerPricingGroupApproval', {
        levelEntryKey: this.levelEntryKey,
        approved,
      });
    },

    categoryManagerCategoryApproval({ approved }) {
      this.$emit('categoryManagerCategoryApproval', {
        levelEntryKey: this.levelEntryKey,
        approved,
      });
    },

    approveUnit() {
      this.$emit('unitApproval', { levelEntryKey: this.levelEntryKey });
    },

    getCompetitorTargetDistance() {
      const hierarchy = this.getHierarchy(h => h.levelEntryKey === this.levelEntryKey)[0];
      this.competitorTargetDistances = get(hierarchy, 'competitorTargetDistances', []);
    },

    updateCompetitorTargetDistances(competitorTargetDistances) {
      this.updateTargetDistances({
        levelEntryKey: this.levelEntryKey,
        updates: { competitorTargetDistances },
      });
    },

    triggerRecalculate() {
      if (!isEmpty(this.recalculatingUpperHierarchy)) {
        this.recalculateConfirmationDialog = true;
        return;
      }

      this.recalculate();
    },

    async recalculate() {
      this.recalculateConfirmationDialog = false;

      await this.runEngineForPricingGroups({
        levelEntryKey: this.levelEntryKey,
        level: this.level,
      });
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';
@import '@style/components/_hierarchy-item-shared.scss';

.hierarchy-level-item__group-end {
  .actions {
    @include hierarchy-item-actions-panel();
  }

  .v-btn {
    font-size: 1.25rem;
  }

  .v-messages {
    display: none;
  }

  .v-input__slot {
    margin-bottom: unset !important;
  }

  .checkbox {
    padding-top: $hierarchy-item-checkbox-padding-top;
  }

  .hierarchy-level-item__approve-btn.v-btn {
    height: 2.4rem;
    padding: unset;
    margin-right: 0.5rem !important;
    &.approval-workflow.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: #4caf50 !important;
    }
  }

  .hierarchy-level-item__recalculate-btn.v-btn {
    height: 2.4rem;
    padding: unset;
    margin-right: 0 !important;

    .v-btn__loader {
      .v-progress-circular.v-progress-circular--indeterminate {
        height: 1.8rem !important;
        width: 1.8rem !important;
      }
    }
  }
}

.alert-cell {
  background-color: $pricing-background !important;
  border-bottom: none !important;
  width: $grid-view-alert-column-width !important;
  border-top: none !important;
}

//* { outline: solid 0.25rem hsla(210, 100%, 100%, 0.5); }
.hierarchy-level-item {
  &__heading {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &--level-0 {
    td:not(.alert-cell) {
      border-top: solid 0.2rem $pricing-light-blue;
    }
    .hierarchy-level-item__heading {
      color: $pricing-light-blue;
    }
  }

  &--level-1 {
    td:not(.alert-cell) {
      border-top: solid 0.1rem $pricing-grey-dark;
    }

    .hierarchy-level-item__recalculate-btn.v-btn {
      min-width: 3rem;
      width: 3rem;
    }
  }

  &--level-2 {
    td:not(.alert-cell) {
      border-bottom: solid 0.1rem $pricing-grey-dark;
    }

    .hierarchy-level-item__recalculate-btn.v-btn {
      min-width: 3rem;
      width: 3rem;
    }
  }

  &--level-3 {
    td {
      border-bottom: solid 0.1rem $pricing-grey-dark;
      &.hierarchy-level-item__heading {
        font-weight: 300;
      }

      &.hierarchy-level-item__heading.disabled {
        color: #8ba4b7;
      }
    }
  }

  &__main {
    color: $text-color;
    td:not(.alert-cell) {
      font-size: 1.3rem;
      line-height: 1.7rem;

      &.highlight {
        background-color: $grid-view-highlight;
      }
    }
  }

  &__data-cell {
    border-bottom: solid 0.1rem $pricing-grey-dark;
    font-weight: 600;
    input {
      padding: 0;
      text-align: right;
      font-size: 1.3rem;
    }
    .v-input {
      padding: 0;
      margin: 0;
    }
    .v-text-field__details {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}

.border-right {
  border-right: 0.1rem solid $pricing-grey-dark;
}

.border-bottom {
  border-bottom: 0.1rem solid $pricing-grey-dark;
}

.remove-margin {
  margin: 0;
}

.store-group-expand {
  text-align: right;
}

.recalculate-dialog {
  font-size: 1.3rem;
  line-height: 2rem;
}
</style>
